<template>
    <section class="settings-timing">
        <h4 class="settings-timing__title sr-only h3">Нормы тайминга</h4>
        <form class="settings-timing__settings" @submit.prevent>
            <fieldset class="settings-timing__fieldset settings-timing__fieldset_main">
                <select class="settings-timing__select" v-model="modeModel">
                    <option class="settings-timing__option"
                        v-for="mode in modes"
                        :key="mode.id"
                        :value="mode.id"
                    >
                        {{mode.title}}
                    </option>
                </select>
                <select class="settings-timing__select" v-model="timezoneModel">
                    <option class="settings-timing__option"
                        v-for="timezone in timezones"
                        :key="timezone.id"
                        :value="timezone.id"
                    >
                        {{timezone.title}}
                    </option>
                </select>
            </fieldset>
            <fieldset class="settings-timing__fieldset settings-timing__fieldset_daterange">
                <month-picker class="settings-timing__datepicker settings-timing__datepicker_standard"
                    v-if="modeId === 'byMonth'"
                    name="standardDate"
                    v-model="standardDateModel"
                >
                    Месяц нормы:
                </month-picker>
            </fieldset>
            <p class="settings-timing__tip">{{mode.tip}}</p>
        </form>
        <spinner class="settings-timing__spinner" v-if="status === 'loading'" />
        <error-message class="settings-timing__error-message" v-else-if="status === 'error'">
            Не удалось загрузить настройки тайминга.
        </error-message>
        <form v-else class="settings-timing__standard box" @submit.prevent="submitTimingStandard">
            <div class="settings-timing__standard-controls">
                <div class="settings-timing__standard-control">
                    <label class="settings-timing__standard-label h3" for="cooking">На кухне</label>
                    <input class="settings-timing__standard-input"
                        type="text"
                        id="cooking"
                        :class="{ 'settings-timing__standard-input_invalid': standard.cooking_duration.isValueInvalid }"
                        :value="standard.cooking_duration.inputValueAsMinuteString"
                        @input="handleTimingStandardInput('cooking_duration', $event)"
                    >
                    <span class="settings-timing__standard-error" v-if="standard.cooking_duration.error">
                        {{standard.cooking_duration.error}}
                    </span>
                </div>
                <div class="settings-timing__standard-control">
                    <label class="settings-timing__standard-label h3" for="stand">В отстойнике</label>
                    <input class="settings-timing__standard-input"
                        type="text"
                        id="stand"
                        :class="{ 'settings-timing__standard-input_invalid': standard.stand_duration.isValueInvalid }"
                        :value="standard.stand_duration.inputValueAsMinuteString"
                        @input="handleTimingStandardInput('stand_duration', $event)"
                    >
                    <span class="settings-timing__standard-error" v-if="standard.stand_duration.error">
                        {{standard.stand_duration.error}}
                    </span>
                </div>
                <div class="settings-timing__standard-control">
                    <label class="settings-timing__standard-label h3" for="waiting">До отправки</label>
                    <input class="settings-timing__standard-input"
                        type="text"
                        id="waiting"
                        :value="standardWaitingDuration | durationFormat"
                        disabled
                    >
                </div>
                <div class="settings-timing__standard-control">
                    <label class="settings-timing__standard-label h3" for="delivery">В пути</label>
                    <input class="settings-timing__standard-input"
                        type="text"
                        id="delivery"
                        :class="{ 'settings-timing__standard-input_invalid': standard.delivery_duration.isValueInvalid }"
                        :value="standard.delivery_duration.inputValueAsMinuteString"
                        @input="handleTimingStandardInput('delivery_duration', $event)"
                    >
                    <span class="settings-timing__standard-error" v-if="standard.delivery_duration.error">
                        {{standard.delivery_duration.error}}
                    </span>
                </div>
                <div class="settings-timing__standard-control">
                    <label class="settings-timing__standard-label h3" for="total">Общее</label>
                    <input class="settings-timing__standard-input"
                        type="text"
                        id="total"
                        :value="standardTotalDuration | durationFormat"
                        disabled
                    >
                </div>
                <div class="settings-timing__standard-control">
                    <label class="settings-timing__standard-label h3" for="return">Обратно</label>
                    <input class="settings-timing__standard-input"
                        type="text"
                        id="return"
                        :class="{ 'settings-timing__standard-input_invalid': standard.return_duration.isValueInvalid }"
                        :value="standard.return_duration.inputValueAsMinuteString"
                        @input="handleTimingStandardInput('return_duration', $event)"
                    >
                    <span class="settings-timing__standard-error" v-if="standard.return_duration.error">
                        {{standard.return_duration.error}}
                    </span>
                </div>
            </div>
            <div class="settings-timing__standard-footer">
                <button class="settings-timing__standard-button settings-timing__standard-button_save" :disabled="isSubmitButtonDisabled">
                    {{submitButtonText}}
                </button>
            </div>
        </form>
    </section>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";
    import MonthPicker from "@/components/MonthPicker";
    import { dateKey } from "@/helpers/daterange";

    export default {
        name: "SettingsTiming",
        components: {
            MonthPicker
        },
        data() {
            return {
                status: "loading",
                standard: {
                    cooking_duration: {
                        originalValueAsSecondsNumber: null,
                        inputValueAsSecondsNumber: null,
                        inputValueAsMinutesNumber: null,
                        inputValueAsMinuteString: null,
                        isSameValue: null,
                        isValueInvalid: null,
                        error: ""
                    },
                    stand_duration: {
                        originalValueAsSecondsNumber: null,
                        inputValueAsSecondsNumber: null,
                        inputValueAsMinutesNumber: null,
                        inputValueAsMinuteString: null,
                        isSameValue: null,
                        isValueInvalid: null,
                        error: ""
                    },
                    delivery_duration: {
                        originalValueAsSecondsNumber: null,
                        inputValueAsSecondsNumber: null,
                        inputValueAsMinutesNumber: null,
                        inputValueAsMinuteString: null,
                        isSameValue: null,
                        isValueInvalid: null,
                        error: ""
                    },
                    return_duration: {
                        originalValueAsSecondsNumber: null,
                        inputValueAsSecondsNumber: null,
                        inputValueAsMinutesNumber: null,
                        inputValueAsMinuteString: null,
                        isSameValue: null,
                        isValueInvalid: null,
                        error: ""
                    }
                },
                submitButtonText: "Сохранить",
            };
        },
        computed: {
            ...mapState({
                modes: state => state.settings.timing.standard.modes,
                modeId: state => state.settings.timing.standard.modeId,
                timezones: state => state.settings.timing.standard.timezones,
                timezoneId: state => state.settings.timing.standard.timezoneId,
                standardDate: state => state.settings.timing.standard.date,
                standardData: state => state.settings.timing.standard.data
            }),
            standardDateModel: {
                get() {
                    return this.standardDate;
                },
                set(value) {
                    this.setTimingStandardParameter({ parameter: "date", value });
                }
            },
            mode() {
                return this.modes.find(({id}) => id === this.modeId);
            },
            modeModel: {
                get() {
                    return this.modeId;
                },
                set(value) {
                    this.setTimingStandardParameter({ parameter: "modeId", value });
                }
            },
            timezoneModel: {
                get() {
                    return this.timezoneId;
                },
                set(value) {
                    this.setTimingStandardParameter({ parameter: "timezoneId", value });
                }
            },
            dataKey() {
                return `${this.modeId === "byMonth" ? dateKey(this.standardDate) : "default"}-${this.timezoneId}`;
            },
            standardDateData() {
                return this.standardData[this.dataKey];
            },
            standardWaitingDuration() {
                if (
                    this.standard.cooking_duration.isValueInvalid ||
                    this.standard.stand_duration.isValueInvalid
                ) {
                    return "";
                }

                return this.standard.cooking_duration.inputValueAsSecondsNumber + this.standard.stand_duration.inputValueAsSecondsNumber;
            },
            standardTotalDuration() {
                if (
                    this.standard.cooking_duration.isValueInvalid ||
                    this.standard.stand_duration.isValueInvalid ||
                    this.standard.delivery_duration.isValueInvalid
                ) {
                    return "";
                }

                return this.standardWaitingDuration + this.standard.delivery_duration.inputValueAsSecondsNumber;
            },
            isSubmitButtonDisabled() {
                let parameters = Object.values(this.standard);

                return parameters.some(({ isValueInvalid }) => isValueInvalid) || parameters.every(({ isSameValue }) => isSameValue);
            }
        },
        methods: {
            ...mapMutations([
                "setTimingStandardParameter",
            ]),
            ...mapActions([
                "requestTimingStandard",
                "saveTimingStandardToDatabase",
                "createMessage",
            ]),
            showData() {
                Object.keys(this.standard).forEach(parameter => {
                    this.standard[parameter].originalValueAsSecondsNumber = this.standardDateData[parameter];
                    this.standard[parameter].inputValueAsSecondsNumber = this.standardDateData[parameter];
                    this.standard[parameter].inputValueAsMinutesNumber = this.standardDateData[parameter] / 60;
                    this.standard[parameter].inputValueAsMinuteString = String(this.standard[parameter].inputValueAsMinutesNumber);
                    this.standard[parameter].isSameValue = true;
                    this.standard[parameter].isValueInvalid = false;
                    this.standard[parameter].error = "";
                });

                this.submitButtonText = "Сохранить",
                this.status = "success";
            },
            showError() {
                this.status = "error";
            },
            loadTimingStandard() {
                this.status = "loading";

                let parameters = {
                    time_zone: this.timezoneId
                };

                if (this.modeId === "byMonth") {
                    parameters.date = dateKey(this.standardDate);
                }

                this.requestTimingStandard(parameters).then(this.showData, this.showError);
            },
            handleTimingStandardInput(parameter, { target: { value }}) {
                this.standard[parameter].inputValueAsMinuteString = value;
                this.standard[parameter].inputValueAsMinuteNumber = Number(value);
                this.standard[parameter].inputValueAsSecondsNumber = this.standard[parameter].inputValueAsMinuteNumber * 60;

                this.standard[parameter].isSameValue =
                    this.standard[parameter].inputValueAsSecondsNumber === this.standard[parameter].originalValueAsSecondsNumber;

                this.standard[parameter].isValueInvalid =
                    Number.isNaN(this.standard[parameter].inputValueAsMinuteNumber) ||
                    this.standard[parameter].inputValueAsMinuteNumber === 0 ||
                    !Number.isSafeInteger(this.standard[parameter].inputValueAsMinuteNumber);

                this.standard[parameter].error = this.standard[parameter].isValueInvalid ? "Некорректное значение" : "";
            },
            submitTimingStandard() {
                this.submitButtonText = "Сохранение...";

                let parameters = {
                    time_zone: this.timezoneId,
                    cooking_duration: this.standard.cooking_duration.inputValueAsSecondsNumber,
                    stand_duration: this.standard.stand_duration.inputValueAsSecondsNumber,
                    delivery_duration: this.standard.delivery_duration.inputValueAsSecondsNumber,
                    return_duration: this.standard.return_duration.inputValueAsSecondsNumber
                };

                if (this.modeId === "byMonth") {
                    parameters.date = dateKey(this.standardDate);
                }

                this.saveTimingStandardToDatabase(parameters).then(() => {
                    this.createMessage({
                        type: "success",
                        text: "Изменения сохранены"
                    });
                }).catch(() => {
                    this.createMessage({
                        type: "failure",
                        text: "Не удалось сохранить изменения"
                    });
                }).finally(() => {
                    this.submitButtonText = "Сохранить";
                });
            }
        },
        created() {
            if (this.standardDateData) {
                this.showData();
            } else {
                this.loadTimingStandard();
            }
        },
        watch: {
            standardDateData(standardDateData) {
                if (!standardDateData) {
                    this.loadTimingStandard();
                } else {
                    this.showData();
                }
            }
        },
    }
</script>

<style lang="scss">
    .settings-timing__title {
        margin-bottom: 10px;
    }
    .settings-timing__datepicker {
        &_standard {
            margin-bottom: 10px;
        }
    }
    .settings-timing__fieldset {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }

        &_main {
            margin-bottom: 15px;
        }
    }
    .settings-timing__tip {
        margin-bottom: 10px;
    }
    .settings-timing__select {
        min-width: 240px;
        height: 40px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;
    }
    .settings-timing__standard {
        max-width: 400px;
        padding: 24px 16px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;

        @include desktop {
            max-width: none;
        }
    }
    .settings-timing__standard-controls {
        margin-bottom: 15px;

        @include desktop {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 15px;
        }
    }
    .settings-timing__standard-control {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 16px;
        position: relative;

        & + & {
            margin-top: 8px;

            @include desktop {
                margin-top: 0;
            }
        }
    }
    .settings-timing__standard-label {
        margin: 0 0 5px;
    }
    .settings-timing__standard-input {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid $gray-line;
        border-radius: 4px;

        font-size: 16px;
        line-height: 1;

        &[disabled] {
            background-color: $gray-line;
        }

        @include desktop {
            font-size: 20px;
        }

        &_invalid {
            border-color: $red-secondary;
        }
    }
    .settings-timing__standard-error {
        color: $red-secondary;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .settings-timing__standard-footer {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .settings-timing__standard-button {
        padding: 10px;
        border-radius: 6px;

        &[disabled] {
            background-color: $gray-form;
        }

        &_save {
            min-width: 100px;
            color: #fff;
            font-size: 14px;
            background-color: rgba($red-secondary, 0.7);

            @include desktop {
                min-width: 120px;
                font-size: 16px;
            }
        }
    }
</style>
